<template>
  <div class="bg-mafia-dark flex-column position-relative">
    <div class="container">
      <div class="row">
        <div class="logo_top mt-200 mb-50">
          <img :src="require('@/assets/image/logo_60.png')" alt="마피아">
          <div class="text_guide pt-10">
            <h4>MAFIA에서<br>
              활동할 닉네임을 설정하세요</h4>

            <span class="alert_info error" style="font-size: 13px">닉네임은 설정 후 변경이 불가능합니다.</span>
          </div>
        </div>
        <div class="pt-30" >
          <div class="dark_input input_count">
            <input
                v-model="account.nick"
                type="text" placeholder="닉네임"  maxlength='20'
                :rules="[$rules.trim(account, 'nick')]"
            >
            <!-- TODO : 텍스트 수 카운트 필요 -->
            <span class="input_text_count">{{ account.nick.length }}/20</span>
          </div>
          <div class="dark_input input_count mt-10">
            <input
                v-model="account.memo"
                type="text" placeholder="상태메시지"  maxlength='30'>
            <!-- TODO : 텍스트 수 카운트 필요 -->
            <span class="input_text_count">{{ account.memo.length }}/30</span>
          </div>
        </div>
        <div class="intro_btn_wrap">

          <form
              style="display: none"
              name="form_chk" method="get">
            <input type="hidden" name="m" value="checkplusService">     <!-- 필수 데이타로, 누락하시면 안됩니다. -->
            <input type="hidden" name="EncodeData" value="">  <!-- 위에서 업체정보를 암호화 한 데이타입니다. -->
            <input type ="hidden" name="recvMethodType" value ="get">
            <!--
            <a href="javascript:fnPopup();"> CheckPlus 안심본인인증 Click</a>
            -->
          </form>
        </div>
      </div>
    </div>
    <div class="intro_btn_wrap mb-20">
      <div class="btn btn_area">
        <button class="btn_l btn_fill_blue" @click="postAccount">확인</button>
      </div>

    </div>
    <PIN
        v-if="is_pin"
        :options="pin_option"
        style="position: fixed; left: 0; top: 0; width: 100%; height: 100%"

        @click="pinClick"
        @cancel="pinCancel"
    ></PIN>
  </div>
</template>

<script>

	import PIN from "@/view/Auth/mafia007";

	export default {
		name: 'nice'
		, props: ['Axios']
		, components: {PIN}
		, data: function(){
			return {
				program: {
					name: '나이스 본인인증'
					, not_header: true
					, not_footer: true
          , type: 'auth'
				}
				,account: {
					nick: ''
					, memo: ''
					, auth: ''
					, HPNO: ''
				}
				,is_pin: false
				,pin_option: {
					pin_type: 'set'
				}
			}
		}
		,methods: {
			postAccount: async function(){
				try {
					if (!this.account.nick || !this.account.memo) {
						throw '닉네임과 상태메시지를 입력하세요'
					}

					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_account
						, data: {
							nickname: this.account.nick
							, state_message: this.account.memo
						}
						, type: true
					})

					if(result.success){
						this.$bus.$emit('to', { name: 'mafia010'})
					}else{
						if(result.code == 'E000400017'){
							this.$bus.$emit('to', { name: 'sms'})
						}else{
							throw result.message
						}
					}

				}catch (e){
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postAccount2: async function () {


				try {

					if(!this.account.nick || !this.account.memo){
						throw '닉네임과 상태메시지를 입력하세요'
					}
					const result = await this.$Request({
						method: 'post'
						, url: 'https://nice.reappay.net/check-pay-plus/request'
						, header: {
							'Content-Type': 'application/json; charset=utf-8'
						}
						, isResult: true
					})

					if (result.success) {

						this.fnPopup(result.data.enc_data)

						window.addEventListener('message',  (e) => {
							this.$log.console('on message !!')
							for(let ee in e){
								this.$log.console(ee, e[ee])
							}
							const call = e.data

							if (call.success) {
								this.$set(this.account, 'auth', call.payload)
								this.$set(this.account, 'HPNO', call.phone_no)

								this.$log.console(this.account)

								this.isSmsConfirm = true
								this.isCode = true

								let join_info = localStorage.getItem('join_info')
								if(!join_info){
									this.$bus.$emit('notify',  {type: 'error', message: '가입 정보가 없습니다'})
								}
								join_info = JSON.parse(join_info)
								join_info.nick = this.account.nick
								join_info.memo = this.account.memo
								localStorage.setItem('join_info', JSON.stringify(join_info))
								this.is_pin = true

							} else {
								this.isSmsConfirm = false
								this.isCode = false
								this.$bus.$emit('notify',  {type: 'error', message: call.resp_message})

							}
						})
					} else {
						this.isSmsConfirm = false
						this.isCode = false
						this.$bus.$emit('notify',  {type: 'error', message: result.data.resp_message})
					}

				} catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					//
				}
			}
			, fnPopup(encode) {
				window.name = "Parent_window";
				let w = window.open('', 'popupChk', 'height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
				document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
				document.form_chk.target = "popupChk";
				document.form_chk.EncodeData.value = encode
				this.$log.console('document.form_chk.EncodeData', document.form_chk.EncodeData.value)
				document.form_chk.submit();

				return w
			}
			,pinClick: async function(){
				let data = localStorage.getItem('join_info')
				if(!data){
					this.$bus.$emit('notify',  { type: 'error', message: '가입 정보가 없습니다'})
					this.$emit('to', { name: 'join'})

					return false
				}

				data = JSON.parse(data)
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						,url: ''
						,data: data
					})

					if(result.success){
						this.$emit('to', { name: 'mafia010'})
					}else{
						throw result.message
					}
					this.is_pin = false
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					this.$log.console(e)
				}finally {
					this.$bus.$emit('on', false)

					this.$log.console(this.item)

					if(process.env.VUE_APP_TYPE == 'sample'){
						this.$log.console('do sample')
						let result = await this.$sample.postJoin(data)
						this.$log.console('finish sample')
						this.$log.console(result)
						if(result.success){
							localStorage.setItem('account', JSON.stringify(result.data))
							this.$emit('to', { name: 'mafia010'})
							this.is_pin = false
						}else{
							this.$bus.$emit('notify',  { type: 'error', message: result.message })
						}
					}
				}
				this.pin_option.pin_type = 'set'
			}
			,pinCancel: function(){
				this.$log.console('cancel')
				this.is_pin = false
				this.pin_option.pin_type = 'set'
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
		}
	}
</script>
<style>
img {
  width: 50px;
  height: 50px;
}

.btn_l {
  display: inline-block;
  height: 3.5rem;
  line-height: 1rem;
  min-width: 9rem;
  padding: 0 3rem;
  border-radius: 5rem;
  border-style: solid;
  border-width: 0.1rem;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
  box-sizing: border-box;
}

.intro_btn_wrap { margin: 0 2rem; left: 0; right: 0; bottom: 3rem; }
.btn_area { display: flex; margin: 0 -0.5rem; }
.btn_area button, .btn_area a { margin: 0 0.5rem; padding: 0 1rem; flex: 1; }

/* 입력창 텍스트 카운트 */
.input_count { position: relative; }
.dark_input.input_count input[type=text],
.dark_input.input_count input[type=number]{ padding: 0 7rem 0 2rem; }
.input_count .input_text_count {
  position: absolute; display: flex; height: 4rem; align-items: center;  bottom: 0; color: var(--gray02); font-size: 1.2rem; right: 70px;

}


.dark_input input[type=text]{
  width:80%;
  height: 4rem;
  line-height: 48px;
  padding: 0 20px;
  font-size:14px;
  color:#fff;
  border:1px solid var(--line-white01);
  border-radius: 50px;
  background:var(--bg-Blue01);
  vertical-align:middle;
  box-sizing: border-box;
}

.flex-column {
  justify-content: space-between;
}

.text_guide > h4 {
  color : white;
}


/*input {
  min-height: 100px;
  display: inline-flex;
  align-items: center;
}*/
</style>